<template>
    <div class="likebox_wrapper">
        <iframe src="//www.facebook.com/plugins/likebox.php?href=https%3A%2F%2Fwww.facebook.com%2Fpages%2FGrete-Gravaas-Relasjon-Dialog%2F352289418271268&amp;width=500&amp;height=590&amp;colorscheme=light&amp;show_faces=true&amp;header=true&amp;stream=true&amp;show_border=true"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
        >
    </iframe>
    </div>
</template>

<script>
export default {
  name: 'grete-facebook',
}
</script>

<style lang="scss" scoped>
    .likebox_wrapper {
        height: 600px;
        display: flex;
        justify-content: center;
        iframe {
            max-width: 500px;
            height:100%;
            border:none;
            overflow:hidden;
        }
    }
</style>

