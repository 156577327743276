<template>
  <div class="home">
    <banner />
    <tag-line />
    <offers />
    <hr>
    <facebook />
  </div>
</template>

<script>
import tagLine from '@/components/TagLine.vue'
import offers from '@/components/Offers.vue'
import facebook from '@/components/Facebook.vue'
import banner from '@/components/Banner.vue'
export default {
  name: 'grete-home',
  components: {
    tagLine,
    offers,
    facebook,
    banner
  }
}
</script>
