import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'

export const router = createRouter({
  hash: false,
  history: createWebHistory(),
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/om-grete',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/kontakt',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
    },
    {
      path: '/priser',
      name: 'prices',
      component: () => import(/* webpackChunkName: "prices" */ './views/Prices.vue')
    },
    {
      path: '/terapi/:id',
      name: 'article',
      component: () => import(/* webpackChunkName: "article" */ './views/Article.vue')
    }
  ]
})
