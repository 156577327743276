<template>
    <div>
        <h4>
            {{ $store.state.languageString.offers.title }}
        </h4>
        <section class="expertise clearfix">
            <div
                class="col5"
                v-for="(item, index) in $store.state.offers"
                :key="index"
                >
                <h3>{{ item.title }}</h3>
                <router-link :to="item.url">
                    <img
                        :src="require(`@/assets/${item.img}`)"
                        alt="Illustrasjonsbilde"
                    >
                </router-link>
                <article>
                    <p>{{ item.ingress }}</p>
                </article>
                <router-link :to="'/' + item.url" class="readmore">
                    {{ $store.state.languageString.readMore }}
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'grete-offers',
}
</script>
