<template>
  <carousel :autoplay="5000" :wrap-around="true">
    <slide :key="1">
      <img :src="require(`@/assets/_forside/banner.png`)" />
      <div class="slidertext">
        <p>«We live in the shelter of each other» -Celtic saying</p>
      </div>
    </slide>
    <slide :key="2">
      <img :src="require(`@/assets/_forside/hc_slide_2.jpg`)" />
      <div class="slidertext">
        <p>«Love is the only safety life ever offers us» -Sue Johnson</p>
      </div>
    </slide>
    <slide :key="3">
      <img :src="require(`@/assets/_forside/Tverkulturelt_ekteskap1.jpg`)" />
      <div class="slidertext">
        <p>
          «If we are going to live with our deepest differences then we must
          learn about one another» - Deborah J. Levine
        </p>
      </div>
    </slide>
    <slide :key="4">
      <img :src="require(`@/assets/_forside/kaffe.jpg`)" />
      <div class="slidertext">
        <p>«Conversation is food for the soul» -Mexican Proverb</p>
      </div>
    </slide>
    <template #addons>
      <navigation />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "grete-banner",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>

<style lang="scss">
.slidertext {
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 60px;
  right: 20px;
  padding: 20px;
  max-width: 268px;
}

img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
</style>
