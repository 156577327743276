<template>
    <section class="top clearfix">
            <div class="logo">
                <a href="https://gretegravaas.no/"><img src="../assets/logo.png"></a>
            </div>
            <nav class="clearfix">
                <div class="mobilemenubutton clicktoggle"></div>
                <ul class="togglethis">
                    <li>
                        <router-link
                            :class="$route.path === '/' ? 'active' : ''"
                            to="/"
                        >
                            Hjem
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :class="$route.path.includes('om-grete') ? 'active' : ''"
                            to="/om-grete"
                        >
                            Om meg
                        </router-link>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.facebook.com/pages/Grete-Gravaas-Relasjon-Dialog/352289418271268">
                            Facebook
                        </a>
                    </li>
                    <li>
                        <router-link
                            :class="$route.path.includes('priser') ? 'active' : ''"
                            to="/priser"
                        >
                            Priser
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :class="$route.path.includes('kontakt') ? 'active' : ''"
                            to="/kontakt"
                        >
                            Kontakt
                        </router-link>
                    </li>
                </ul>
            </nav>
        </section>
</template>

<script>
export default {
  name: 'grete-top',
}
</script>
