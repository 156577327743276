<template>
  <div id="app">
    <contact-ribbon />
    <div class="wrapper">
      <top-component />
      <router-view />
    </div>
    <footer-component />
  </div>
</template>

<script>
import contactRibbon from "@/components/ContactRibbon.vue";
import topComponent from "@/components/Top.vue";
import footerComponent from "@/components/Footer.vue";
export default {
  name: "app",
  components: {
    contactRibbon,
    topComponent,
    footerComponent,
  },
};
</script>

<style lang="scss">
// Node modules
$FontPathOpenSans: "../node_modules/open-sans-fonts/open-sans";
@import "~open-sans-fonts/open-sans";
@import "~@fortawesome/fontawesome-free/css/all.css";
</style>

<style lang="scss">
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Asap", sans-serif;
  color: #191a2f;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*clearfix*/
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.clearfix {
  display: inline-block;
}
html[xmlns] .clearfix {
  display: block;
}
* html .clearfix {
  height: 1%;
}
</style>

<style lang="scss">
/*General*/

.top nav ul li a,
h1,
h2,
h3,
h4,
a.readmore {
  text-transform: uppercase;
}

h1,
h2 {
  font-size: 42px;
  padding: 0 0 40px 0;
}

h3 {
  font-size: 24px;
  padding: 0 0 30px 0;
}

h4 {
  font-size: 18px;
  padding: 0 0 24px 0;
}

h5 {
  font-size: 16px;
  line-height: 1.5em;
  padding: 12px 0;
}

p,
p b,
p i,
figure,
figcaption {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 12px 0;
}

p b,
em {
  font-weight: bold;
}

p i {
  text-decoration: inherit;
}

a {
  text-decoration: none;
}

a:hover,
a.active {
  color: #005ab0;
}

p a {
  line-height: 1.5em;
}

a.readmore {
  font-size: 18px;
}

p i {
  font-style: italic;
}

textarea.form-control {
  height: 200px;
}

hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 60px 0;
}

hr.topline {
  margin-top: 0;
}

img {
  max-width: 100%;
}

img.subbanner {
  width: 100%;
}

figure {
  padding: 0;
}

figcaption {
  padding-left: 20px;
}

figcaption,
figure {
  color: #333;
  background-color: #e4e4e4;
}

article table tbody {
  border: solid 1px #ccc;
}

article table tr td,
article table tr th {
  font-family: Arial, sans-serif;
  font-size: 16px;
  padding: 5px;
  text-align: center;
}

article table tr td:nth-child(2n),
article table tr th:nth-child(2n) {
  border-left: solid 1px #ccc;
}

article table tr:nth-child(4) {
  border-bottom: solid 1px #ccc;
}

article table tr th {
  font-weight: bold;
  padding-top: 10px;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

nav.help {
  padding-bottom: 20px;
}

nav.help a {
  color: #005ab0;
  cursor: pointer;
}

div.help {
  border: thin solid #005ab0;
  padding: 20px;
  margin: 10px;
}

.col1 {
  width: 22.9%;
}

.col2 {
  width: 48.6%;
}

.col3 {
  width: 74.3%;
  margin-bottom: 40px;
}

.col5 {
  width: 17.76%;
}

.col1,
.col2,
.col3,
.col4,
.col5 {
  float: left;
}

.col1,
.col2,
.col3,
.col5 {
  margin-right: 2.8%;
}

.col1:last-child,
.col2:last-child,
.col5:last-child {
  margin-right: 0;
}

.col1 img,
.col4,
.col5 img {
  width: 100%;
}

.likebox_wrapper * {
  width: 100% !important;
}

/*Top*/

.topContact {
  background-color: #f1f1f1;
  text-align: right;
  padding-bottom: 15px;
}

.topContact i {
  padding: 15px 0 0 20px;
}

.topContact a:hover,
.topContact a:hover i,
.topContact a,
.topContact a i {
  color: #005ab0;
}

.wrapper {
  width: 1168px;
  margin: 0 auto;
  padding: 0 5%;
}

.top {
  width: 100%;
  position: relative;
  margin: 50px 0;
}

.top .logo {
  float: left;
}

.top nav {
  position: absolute;
  bottom: 0;
  right: 0;
}

.top nav .mobilemenubutton {
  display: none;
}

.top nav ul li {
  float: left;
  padding-left: 30px;
  font-size: 14px;
  font-weight: bold;
}

/*Bottom*/
footer {
  background-color: #ccc;
  padding: 60px 0 80px;
  margin-top: 100px;
  width: 100%;
}
footer p {
  font-family: "Asap", sans-serif;
  font-size: 16px;
  color: #1c1c1c;
}

footer p a {
  color: #005ab0;
  display: block;
}

footer p a:hover {
  text-decoration: underline;
}

footer .col1 {
  width: 20%;
  padding: 30px 0 30px 2.8%;
  border-left: thin solid #afafaf;
  min-height: 170px;
}

footer .col1:first-child {
  border: none;
}

/*Frontpage (and News toppage)*/
.tagline {
  margin: 60px 0;
  padding: 40px 0;
  border-top: solid thin #ccc;
  border-bottom: solid thin #ccc;
}

.tagline h1 {
  font-size: 48px;
  line-height: 1.3em;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  text-transform: none;
}

.submain.news {
  width: 100%;
}

.expertise .col1 article,
.expertise.sidebar article {
  height: 75px;
  margin-bottom: 5px;
  overflow: hidden;
}

.news .col1 article,
.news.sidebar article {
  height: 55px;
  margin-bottom: 5px;
  overflow: hidden;
}

/*Subpage*/

.submain h3 {
  padding: 50px 0 15px 0;
}

.expertise h3 {
  min-height: 2em;
}

.submain p a {
  font-family: Arial, sans-serif;
  color: #005ab0;
}

.submain p a:hover {
  text-decoration: underline;
}

.submain ul li {
  font-family: Arial, sans-serif;
  list-style: disc;
  font-size: 14px;
  line-height: 1.8em;
  margin-left: 14px;
}

.sidebar div {
  margin-bottom: 40px;
}

.submain.news .col1 {
  margin-bottom: 40px;
}

.submain.news .col1:nth-child(4),
.submain.expertise .col1:nth-child(4) {
  margin-right: 0;
}

/*Prices page*/

.prices .price p,
.prices .price span,
.prices .price h2,
.prices .price h3 {
  text-align: center;
}

.prices .col1.price {
  box-sizing: border-box;
  margin-right: 2.1%;
  margin-bottom: 40px;
  border: 1px solid #ccc;
  box-shadow: 0px 2.5px 4px 0 #ccc;
  padding: 30px 15px;
  min-height: 328px;
}

.prices h3,
.prices .price h2 {
  padding: 0 0 10px;
}

.prices .price h2 {
  color: #005ab0;
  font-family: "Arial Black", Arial, sans-serif;
  font-weight: 900;
  font-size: 48px;
  margin-right: -30px;
}

.prices .price p,
.prices .price span {
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: #333;
}

.prices .price p {
  border-bottom: solid 1px #ccc;
  padding: 25px 0;
}

.prices .price p:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.prices .price span {
  padding-bottom: 25px;
  border-bottom: solid 1px #ccc;
  display: block;
}

/*bootstrap class*/
.form-control {
  margin-bottom: 25px;
}

.google-maps {
  position: relative;
  padding-bottom: 40%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 1300px) {
  .wrapper {
    width: auto;
  }
}

@media screen and (max-width: 840px) {
  .wrapper {
    width: auto;
  }

  .top .logo,
  .top nav {
    float: none;
  }

  .top .logo {
    width: 346px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .top nav {
    position: relative;
    background-color: #005ab0;
    min-height: 47px;
    width: 100%;
  }

  .top nav .mobilemenubutton {
    display: block;
    cursor: pointer;
    width: 100%;
  }

  .mobilemenubutton:before {
    content: "";
    position: absolute;
    top: 0.25em;
    right: 0;
    width: 2em;
    height: 0.25em;
    border-top: 0.75em double #fff;
    border-bottom: 0.25em solid #fff;
    margin: 10px;
  }

  .top nav ul {
    padding: 40px 0;
    display: none;
  }

  .top nav ul li {
    float: none;
    padding-left: 0;
  }

  .top nav ul li a {
    line-height: 30px;
    color: #fff;
    text-align: center;
    display: block;
  }

  .col1,
  .col5 {
    width: 48.6%;
  }

  .prices .col1.price {
    width: 47.9%;
  }

  .col1:first-child,
  .col1:nth-child(2),
  .col5 {
    margin-bottom: 40px;
  }

  .col5:last-child {
    margin-bottom: 0px;
  }

  .col1:nth-child(even),
  .col5:nth-child(even) {
    margin-right: 0;
  }

  footer .col1 {
    width: 45.5%;
    margin-bottom: 40px;
  }

  footer .col1:first-child {
    border-left: thin solid #afafaf;
  }

  footer .col1:nth-child(even) {
    border-right: thin solid #afafaf;
  }

  .col3 {
    width: 100%;
    margin-right: 0;
  }

  .sidebar {
    margin-top: 20px;
    padding-top: 60px;
    border-top: solid thin #ccc;
  }

  .sidebar.col1.right {
    float: left !important;
    width: 100%;
  }

  .sidebar.col1 div {
    float: left;
    width: 48.6%;
  }

  .sidebar.col1 div:nth-child(odd) {
    margin-right: 2.8%;
  }
}

@media screen and (max-width: 552px) {
  .tagline h1 {
    font-size: 32px;
  }

  .expertise h3 {
    min-height: auto;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .col1,
  .col5 {
    margin: 0 0 40px 0;
  }

  .col1,
  .col2,
  .col5,
  .prices .col1.price {
    width: 100%;
  }

  .col1:last-child,
  .col5:last-child {
    margin: 0;
  }

  footer .col1 {
    width: 95.6%;
    min-height: 0;
  }

  footer .col1:nth-child(even) {
    border-right: none;
  }

  footer p {
    font-size: 18px;
  }

  .expertise .col1 article {
    height: auto;
    max-height: 85px;
  }

  .expertise .col1 article,
  .expertise.sidebar article {
    height: 85px;
  }

  .news .col1 article {
    height: auto;
    max-height: 63px;
  }

  .sidebar.col1 div {
    width: 100%;
  }

  .sidebar.col1 div:nth-child(odd),
  .col2 {
    margin-right: 0;
  }

  .google-maps {
    padding-bottom: 75%;
  }
}

@media screen and (max-width: 390px) {
  .top .logo,
  .top .logo img {
    width: 100%;
  }
}
</style>
