<template>
    <div class="topContact">
        <div class="wrapper">
            <a :href="'tel:' + $store.state.general.phone">
                <i class="fas fa-phone">
                    {{ $store.state.general.phone }}
                </i>
            </a>
            <a :href="'mailto:' + $store.state.general.email">
                <i class="fas fa-envelope">
                    {{ $store.state.general.email }}
                </i>
            </a>
        </div>
    </div>
</template>
