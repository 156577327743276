<template>
  <footer class="clearfix">
    <div class="wrapper">
      <div class="col1">
        <p>
          "Love is not the icing on the cake of life. It is a basic primary
          need, like oxygen or water. Once we understand and accept this, we can
          more easily get to the heart of relationship problems. "
        </p>
        <p><i>- Sue Johnson</i></p>
      </div>
      <nav class="col1">
        <p>
          <router-link to="/">Hjem</router-link>
          <router-link to="/om-grete">Om meg</router-link>
          <a
            target="_blank"
            href="https://www.facebook.com/pages/Grete-Gravaas-Relasjon-Dialog/352289418271268"
            >Facebook</a
          >
          <router-link to="/priser">Priser</router-link>
          <router-link to="/kontakt">Kontakt</router-link>
        </p>
      </nav>
      <nav class="col1">
        <p>
          <router-link to="/terapi/parterapi">Parterapi</router-link>
          <router-link to="/terapi/familieterapi">Familieterapi</router-link>
          <router-link to="/terapi/samlivskurs">Samlivskurs</router-link>
          <router-link to="/terapi/samtaleterapi">Samtaleterapi</router-link>
          <router-link to="/terapi/interkulturellerelasjoner"
            >Interkulturelle relasjoner</router-link
          >
        </p>
      </nav>
      <div class="col1">
        <p>
          Dalsbergstien 9<br />
          0169 Oslo
        </p>
        <p>
          <a href="mailto:post@gretegravaas.no">post@gretegravaas.no</a>
          +47 47 600 931
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'grete-footer',
}
</script>
